<template>
  <div class="patentCase">
    <el-tabs
      v-model="tagName"
      type="card"
      class="demo-tabs"
      @tab-click="handleClick"
    >
      <el-tab-pane label="患者信息" name="info">
        <info ref="infos"></info>
      </el-tab-pane>
      <!-- <el-tab-pane label="患者信息">患者信息</el-tab-pane>
      <el-tab-pane label="患者信息">患者信息</el-tab-pane>
      <el-tab-pane label="患者信息">患者信息</el-tab-pane> -->
    </el-tabs>
  </div>
</template>
<script>
import { ref } from 'vue'
import info from './case/info.vue'
export default {
  name: 'patentCase',
  components: {
    info,
  },
  setup() {
    const infos = ref(null)
    const tagName = ref('info')

    const handleClick = () => {
      // console.log(tab)
    }
    const infoThing = (item) => {
       infos.value.InfoThing(item)
    }
    return {
      tagName,
      handleClick,
      infos,
      infoThing
    }
  },
}
</script>

<style scoped lang="scss"></style>
